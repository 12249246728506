import { usePermissionsStoreNew } from '~/stores/usePermissionsStoreNew'
import { PermissionGuardName } from '~/types/PermissionGuardNameEnum'
import { useAuthCompanyStore } from '~/stores/useAuthCompanyStore'

export default defineNuxtRouteMiddleware(async (to, _from) => {
  // Bypass auth middleware
  if (
    [
      'booking-tester___en___default',
      'booking-isolated-next-operator___en___default',
      'booking-isolated-next-operator___en',
      'booking-isolated-next-operator___es',
      'booking-isolated-next-operator___it',
      'booking-isolated-next-operator___da',
      'booking-isolated-organisation-operator___en___default',
      'booking-isolated-organisation-operator___en',
      'booking-isolated-organisation-operator___es',
      'booking-isolated-organisation-operator___it',
      'booking-isolated-organisation-operator___da',
      'booking-isolated-organisation-operator___en___default',
      'booking-isolated-view-id___en___default',
      'booking-isolated-payment-id___en___default',
      'booking-view-id___en___default',
      'booking-isolated-operator___en___default',
      'booking-isolated-next-cfwt-next___en___default',
      'forgot-password___en___default',
      'update-password___en___default',
    ].includes(to.name)
  ) {
    return
  }
  const user = useSupabaseUser()
  const permissionsStore = usePermissionsStoreNew()
  await permissionsStore.updatePermissions()
  const authCompanyStore = useAuthCompanyStore()
  if (!authCompanyStore.company.id) {
    await authCompanyStore.fetchCompany()
  }
  const hasOrganisationAccess = permissionsStore.hasPermission(
    PermissionGuardName.ORGANISATION_ALL_ACCESS,
    false,
  )
  if (['login___en___default', 'index___en___default'].includes(to.name)) {
    if (user.value) {
      if (hasOrganisationAccess) {
        return navigateTo('/organisation/companies')
      } else {
        if (!authCompanyStore.company?.has_active_subscription) {
          return navigateTo('/tours/manage')
        }
        return navigateTo('/check-in')
      }
    }
    return
  }
  if (!user.value && to.name !== 'register___en___default') {
    return navigateTo('/login')
  }
})
